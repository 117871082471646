<script setup lang="ts">
import ProjectCard from '~/components/site/cards/ProjectCard.vue';
import { getProjects } from '~/api/projects';
import { type EntityType, getEntityText } from '~/enitityHelper';
const props = withDefaults(defineProps<{ entity: EntityType }>(), { entity: 'project' });
const { data: projects } = await useAsyncData('lastProjects', () => getProjects(props.entity), {
  deep: false,
});
const texts = getEntityText(props.entity || 'project');
</script>

<template>
  <div
    id="last-projects"
    class="last-projects"
  >
    <div class="container">
      <h2>{{ texts.lastProjectsTitle }}</h2>
      <div class="last-projects__lg">
        <div class="row">
          <div
            v-for="(item, idx) in projects"
            :key="idx"
            class="col-lg-6 col-xxl-4"
          >
            <project-card :data="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.last-projects {
  margin: 0 0 80px;
  @include media-breakpoint-down(lg) {
    //overflow: hidden;
    margin-bottom: 60px;
  }
  @include media-breakpoint-down(sm) {
    margin: 20px 0 40px;
    //.container{
    //    overflow: hidden;
    //}
  }

  .last-projects__lg {
    //display: block;
    //@include media-breakpoint-down(lg) {
    //    display: none;
    //}
  }

  //.swiper {
  //    display: none;
  //    @include media-breakpoint-down(lg) {
  //        display: block;
  //        padding-bottom: 20px;
  //    }
  //}

  .row {
    row-gap: 20px;
    @include media-breakpoint-down(lg) {
      row-gap: 10px;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 40px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 20px;
    }
  }
}
</style>
