<script setup lang="ts">
import VHeader from '~/components/site/index/VHeader.vue';
import VButton from '~/components/common/form/VButton.vue';
import VRadio from '~/components/common/form/VRadio.vue';
import AuthModal from '~/components/site/auth/AuthModal.vue';
import { useUserStore } from '~/stores/UsersStore';
import { generateProjectApi } from '~/api/projects';
import { type EntityType, getEntityText } from '~/enitityHelper';

const userStore = useUserStore();
const showAuth = ref(false);
const input = useCookie('query', { maxAge: 60 * 3, default: () => '' });
const analyticsStore = useAnalyticsStore();

watch(input, (newInput) => {
  analyticsStore.analyticsEnterPrompt(newInput);
});

const btnDisabled = ref(false);
async function generateProject(prompt: string, level: string) {
  analyticsStore.analyticsSubmitPrompt(prompt);
  if (prompt.length > 3) {
    btnDisabled.value = true;
    if (userStore.user) {
      const p = await generateProjectApi(prompt, level, props.entity);
      if (window && window.ym) {
        window.ym(95948387, 'reachGoal', 'GENERATE');
      }
      await navigateTo(`/projects/${p.id}`);
    } else {
      showAuth.value = true;
    }
    btnDisabled.value = false;
  }
}

const props = withDefaults(defineProps<{ entity: EntityType }>(), { entity: 'project' });
const level = ref(props.entity);
const texts = getEntityText(props.entity || 'project');
</script>

<template>
  <AuthModal
    v-if="showAuth"
    @close-auth="showAuth = false"
  />
  <div class="page-intro">
    <v-header />
    <div class="container">
      <div class="page-intro__content">
        <h2 v-html="texts.title" />
        <div class="page-intro__generation">
          <div class="page-intro__generation-top">
            <input
              v-model="input"
              class="page-intro__input"
              type="text"
              required
              :placeholder="texts.placeholder"
            />
            <v-button
              :disabled="btnDisabled"
              @click="generateProject(input, level)"
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="overflow: visible"
              >
                <path
                  d="M22.2152 9.90408L17.609 7.65642C17.5543 7.62908 17.5262 7.57361 17.498 7.51814L15.223 2.91189C14.834 2.1072 13.8355 1.77361 13.0301 2.16189C12.7039 2.32161 12.4402 2.58552 12.2808 2.91189L10.0058 7.51814C9.97554 7.56921 9.93819 7.61576 9.89491 7.65642L5.28866 9.9322C4.48397 10.3205 4.15038 11.3197 4.53866 12.1244C4.69873 12.4505 4.96252 12.7143 5.28866 12.8744L9.89491 15.1213C9.9496 15.1494 9.97772 15.2049 10.0058 15.2322L12.309 19.8385C12.6973 20.6431 13.6965 20.9767 14.5012 20.5885C14.8274 20.4287 15.091 20.1648 15.2504 19.8385L17.498 15.2322C17.5262 15.1775 17.5816 15.1213 17.609 15.1213L22.2152 12.8463C23.0199 12.4572 23.3535 11.4588 22.9652 10.6541C22.8262 10.3205 22.5488 10.0713 22.2152 9.90408ZM18.8027 10.2377L16.666 12.097C16.2496 12.4853 15.9441 12.9845 15.8332 13.54L15.2785 16.3142C15.2504 16.426 15.1394 16.5095 15.0285 16.4814C14.973 16.4814 14.9449 16.4533 14.9176 16.3978L13.0582 14.2611C12.6699 13.8447 12.1699 13.54 11.6152 13.4283L8.84022 12.8736C8.72929 12.8463 8.64569 12.7345 8.67382 12.6236C8.67382 12.5689 8.70116 12.5408 8.75663 12.5127L10.8933 10.6533C11.3098 10.265 11.6152 9.7658 11.7262 9.21033L12.2808 6.43611C12.309 6.32439 12.4199 6.2408 12.5308 6.26892C12.5725 6.27588 12.6112 6.2952 12.6418 6.32439L14.5012 8.46111C14.8894 8.87752 15.3894 9.18299 15.9441 9.29392L18.7191 9.84861C18.8301 9.87674 18.9137 9.98767 18.8855 10.0986C18.8713 10.1517 18.8427 10.1999 18.8027 10.2377Z"
                  fill="white"
                  :class="{ 'icon-animate-magic1': btnDisabled }"
                />
                <path
                  d="M7.68927 20.0596L5.96195 19.2167C5.94145 19.2064 5.9309 19.1856 5.92035 19.1648L5.06724 17.4375C4.92135 17.1358 4.54694 17.0107 4.2449 17.1563C4.12257 17.2162 4.02371 17.3151 3.96394 17.4375L3.11084 19.1648C3.09947 19.184 3.08547 19.2015 3.06923 19.2167L1.34192 20.0701C1.04017 20.2157 0.915075 20.5904 1.06068 20.8922C1.1207 21.0145 1.21962 21.1134 1.34192 21.1734L3.06923 22.016C3.08974 22.0265 3.10029 22.0473 3.11084 22.0576L3.97449 23.7849C4.12009 24.0866 4.49479 24.2117 4.79655 24.0661C4.91887 24.0062 5.01773 23.9073 5.0775 23.7849L5.92035 22.0576C5.9309 22.0371 5.9517 22.016 5.96195 22.016L7.68927 21.1629C7.99102 21.017 8.11611 20.6426 7.97051 20.3408C7.91836 20.2157 7.81436 20.1223 7.68927 20.0596ZM6.4096 20.1847L5.60835 20.8819C5.4522 21.0275 5.33765 21.2147 5.29605 21.423L5.08805 22.4633C5.0775 22.5052 5.0359 22.5366 4.9943 22.526C4.9735 22.526 4.96295 22.5155 4.9527 22.4947L4.25544 21.6934C4.10984 21.5373 3.92234 21.423 3.71434 21.3811L2.67373 21.1731C2.63213 21.1629 2.60079 21.121 2.61133 21.0794C2.61133 21.0589 2.62159 21.0483 2.64239 21.0378L3.44364 20.3405C3.59979 20.1949 3.71434 20.0077 3.75594 19.7994L3.96394 18.7591C3.97449 18.7172 4.01609 18.6859 4.05769 18.6964C4.07333 18.699 4.08782 18.7063 4.09929 18.7172L4.79655 19.5185C4.94215 19.6746 5.12965 19.7891 5.33765 19.8308L6.37825 20.0388C6.41986 20.0493 6.4512 20.0909 6.44066 20.1325C6.43533 20.1524 6.42458 20.1705 6.4096 20.1847Z"
                  fill="white"
                  :class="{ 'icon-animate-magic2': btnDisabled }"
                />
              </svg>
              &nbsp;Создать
              <!--                            <i class="icon-magic me-2" />-->
            </v-button>
            <div
              v-if="input !== ''"
              class="page-intro__input-clear"
              @click="input = ''"
            >
              <i
                class="icon-clear"
                role="button"
              />
            </div>
          </div>
          <div class="page-intro__generation-bottom">
            <a
              :class="{ active: level === 'project' }"
              href="/"
            >
              Проект
            </a>
            <a
              :class="{ active: level === 'course-work' }"
              href="/course-work"
            >
              Курсовая работа
            </a>
            <a
              :class="{ active: level === 'referat' }"
              href="/referat"
            >
              Реферат
            </a>
            <a
              :class="{ active: level === 'text' }"
              href="/text"
            >
              Текст
            </a>
          </div>
          <v-button
            class="page-intro__generation-button-md"
            :disabled="btnDisabled"
            @click="generateProject(input, level)"
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="overflow: visible"
            >
              <path
                d="M22.2152 9.90408L17.609 7.65642C17.5543 7.62908 17.5262 7.57361 17.498 7.51814L15.223 2.91189C14.834 2.1072 13.8355 1.77361 13.0301 2.16189C12.7039 2.32161 12.4402 2.58552 12.2808 2.91189L10.0058 7.51814C9.97554 7.56921 9.93819 7.61576 9.89491 7.65642L5.28866 9.9322C4.48397 10.3205 4.15038 11.3197 4.53866 12.1244C4.69873 12.4505 4.96252 12.7143 5.28866 12.8744L9.89491 15.1213C9.9496 15.1494 9.97772 15.2049 10.0058 15.2322L12.309 19.8385C12.6973 20.6431 13.6965 20.9767 14.5012 20.5885C14.8274 20.4287 15.091 20.1648 15.2504 19.8385L17.498 15.2322C17.5262 15.1775 17.5816 15.1213 17.609 15.1213L22.2152 12.8463C23.0199 12.4572 23.3535 11.4588 22.9652 10.6541C22.8262 10.3205 22.5488 10.0713 22.2152 9.90408ZM18.8027 10.2377L16.666 12.097C16.2496 12.4853 15.9441 12.9845 15.8332 13.54L15.2785 16.3142C15.2504 16.426 15.1394 16.5095 15.0285 16.4814C14.973 16.4814 14.9449 16.4533 14.9176 16.3978L13.0582 14.2611C12.6699 13.8447 12.1699 13.54 11.6152 13.4283L8.84022 12.8736C8.72929 12.8463 8.64569 12.7345 8.67382 12.6236C8.67382 12.5689 8.70116 12.5408 8.75663 12.5127L10.8933 10.6533C11.3098 10.265 11.6152 9.7658 11.7262 9.21033L12.2808 6.43611C12.309 6.32439 12.4199 6.2408 12.5308 6.26892C12.5725 6.27588 12.6112 6.2952 12.6418 6.32439L14.5012 8.46111C14.8894 8.87752 15.3894 9.18299 15.9441 9.29392L18.7191 9.84861C18.8301 9.87674 18.9137 9.98767 18.8855 10.0986C18.8713 10.1517 18.8427 10.1999 18.8027 10.2377Z"
                fill="white"
                :class="{ 'icon-animate-magic1': btnDisabled }"
              />
              <path
                d="M7.68927 20.0596L5.96195 19.2167C5.94145 19.2064 5.9309 19.1856 5.92035 19.1648L5.06724 17.4375C4.92135 17.1358 4.54694 17.0107 4.2449 17.1563C4.12257 17.2162 4.02371 17.3151 3.96394 17.4375L3.11084 19.1648C3.09947 19.184 3.08547 19.2015 3.06923 19.2167L1.34192 20.0701C1.04017 20.2157 0.915075 20.5904 1.06068 20.8922C1.1207 21.0145 1.21962 21.1134 1.34192 21.1734L3.06923 22.016C3.08974 22.0265 3.10029 22.0473 3.11084 22.0576L3.97449 23.7849C4.12009 24.0866 4.49479 24.2117 4.79655 24.0661C4.91887 24.0062 5.01773 23.9073 5.0775 23.7849L5.92035 22.0576C5.9309 22.0371 5.9517 22.016 5.96195 22.016L7.68927 21.1629C7.99102 21.017 8.11611 20.6426 7.97051 20.3408C7.91836 20.2157 7.81436 20.1223 7.68927 20.0596ZM6.4096 20.1847L5.60835 20.8819C5.4522 21.0275 5.33765 21.2147 5.29605 21.423L5.08805 22.4633C5.0775 22.5052 5.0359 22.5366 4.9943 22.526C4.9735 22.526 4.96295 22.5155 4.9527 22.4947L4.25544 21.6934C4.10984 21.5373 3.92234 21.423 3.71434 21.3811L2.67373 21.1731C2.63213 21.1629 2.60079 21.121 2.61133 21.0794C2.61133 21.0589 2.62159 21.0483 2.64239 21.0378L3.44364 20.3405C3.59979 20.1949 3.71434 20.0077 3.75594 19.7994L3.96394 18.7591C3.97449 18.7172 4.01609 18.6859 4.05769 18.6964C4.07333 18.699 4.08782 18.7063 4.09929 18.7172L4.79655 19.5185C4.94215 19.6746 5.12965 19.7891 5.33765 19.8308L6.37825 20.0388C6.41986 20.0493 6.4512 20.0909 6.44066 20.1325C6.43533 20.1524 6.42458 20.1705 6.4096 20.1847Z"
                fill="white"
                :class="{ 'icon-animate-magic2': btnDisabled }"
              /></svg
            >&nbsp;Создать
          </v-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.page-intro {
  color: white;
  height: 100vh;
  min-height: 900px;
  background: url('/images/deco.svg') -60px center no-repeat, url('/images/deco.svg') calc(100% + 60px) center no-repeat,
    url('/images/promo-bg.svg') top center no-repeat;
  background-size: auto, auto, cover;
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  @include media-breakpoint-down(xl) {
    min-height: 750px;
    height: auto;
    background: url('/images/promo-bg.svg') top center no-repeat;
    background-size: cover;
  }
  @include media-breakpoint-down(lg) {
    min-height: 550px;
  }
  @include media-breakpoint-down(md) {
    min-height: auto;
  }

  .container {
    justify-self: center;
  }

  .page-intro__content {
    margin-top: 20%;
    position: relative;
    text-align: center;

    @include media-breakpoint-down(md) {
      margin: 120px 0 150px;
    }

    h2 {
      margin-bottom: 45px;
      font-size: 48px;
      @include media-breakpoint-down(lg) {
        margin-bottom: 1rem;
        font-size: 32px;
      }
      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
        font-size: 26px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 20px;
      }
    }

    .page-intro__generation {
      display: flex;
      flex-direction: column;
      gap: 8px;
      text-align: left;
      margin: 0 auto;
      width: 1030px;
      @include media-breakpoint-down(xl) {
        width: auto;
      }

      .page-intro__generation-top {
        position: relative;
        background: white;
        z-index: 1;
        border-radius: 33px;
        margin-bottom: 16px;
        @include media-breakpoint-down(lg) {
          border-radius: 25px;
        }
        @include media-breakpoint-down(md) {
          margin-bottom: 4px;
          border-radius: 16px;
        }

        .page-intro__typed {
          position: absolute;
          color: $primary;
          top: 50%;
          transform: translateY(-50%);
          left: 32px;
          padding-right: 260px;
          font-size: 24px;
          z-index: -1;
          @include media-breakpoint-down(lg) {
            font-size: 18px;
          }
          @include media-breakpoint-down(md) {
            font-size: 14px;
            left: 12px;
            padding-right: 12px;
          }

          &:after {
            content: '|';
            display: inline;
            animation: blink 1s infinite;
          }
        }

        button {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 27px;
          height: 79px;
          padding: 20px 40px;
          border: none;
          font-size: 24px;
          font-weight: 600;
          display: flex;

          i {
            font-size: 30px;
            @include media-breakpoint-down(lg) {
              font-size: 24px;
            }
          }

          @include media-breakpoint-down(lg) {
            font-size: 18px;
            right: 8px;
            height: 50px;
            border-radius: 17px;
            padding: 20px 30px;
          }
          @include media-breakpoint-down(md) {
            display: none;
          }
        }

        .page-intro__input {
          background: transparent;
          border-radius: 33px;
          //border: 8px solid rgba(244, 244, 244, 0.30);
          border: none;
          padding: 35px 240px 35px 35px;
          color: $primary;
          font-size: 24px;
          font-weight: 500;
          width: 100%;
          outline: 8px solid rgba(244, 244, 244, 0.3);
          transition: outline 0.3s;

          &:focus,
          &:active {
            outline: 8px solid rgba(244, 244, 244, 0.6);
            @include media-breakpoint-down(md) {
              outline: none;
            }
          }

          @include media-breakpoint-down(lg) {
            border-radius: 25px;
            padding: 18px 190px 18px 18px;
          }
          @include media-breakpoint-down(md) {
            font-size: 14px;

            padding: 12px 35px 12px 12px;
            outline: none;
          }
        }

        .page-intro__input-clear {
          position: absolute;
          right: 8px;
          display: none;
          top: 50%;
          transform: translateY(-50%);
          color: rgba($primary, 0.7);
          font-size: 20px;
          @include media-breakpoint-down(md) {
            display: block;
          }

          i {
            display: flex;
          }
        }

        .page-intro__input-symbols {
          position: absolute;
          right: 240px;
          top: 50%;
          transform: translateY(-50%);
          color: $primary;
          font-size: 20px;
          @include media-breakpoint-down(lg) {
            right: 185px;
            font-size: 16px;
          }
          @include media-breakpoint-down(md) {
            right: 33px;
            font-size: 12px;
          }
        }
      }

      .page-intro__generation-bottom {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        @include media-breakpoint-down(md) {
          margin-bottom: 16px;
          gap: 6px;
        }

        p {
          display: block;
          @include media-breakpoint-down(md) {
            display: none;
          }
        }

        a {
          color: white;
          padding: 8px 16px;
          border: 1px solid white;
          border-radius: 10px;
          font-size: 16px;

          &:not(.active):hover {
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.1);
            transform: translateY(-2px);
          }

          &.active {
            background-color: white;
            color: $primary;

            &:hover {
              cursor: default;
            }
          }

          @include media-breakpoint-down(md) {
            padding: 4px 8px;
            letter-spacing: -0.01em;
            font-size: 14px;
          }
        }
      }

      .page-intro__generation-button-md {
        display: none;
        @include media-breakpoint-down(md) {
          display: block;
          width: 100%;
          background: rgba(#f4f4f4, 40%);
        }
      }
    }
  }
}
</style>
